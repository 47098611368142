@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');
@import "/src/Resources/scss/variables/_variables.scss";

@mixin phone {
	@media only screen and (max-width: 767px) {
		@content;
	}
}

@mixin big-phone {
	@media only screen and (min-width: 768px) and (max-width: 880px) {
		@content;
	}
}

@mixin tablet {
	@media only screen and (min-width: 881px) and (max-width: 1024px) {
		@content;
	}
}

@mixin big-tablet {
	@media only screen and (min-width: 1025px) and (max-width: 1199px) {
		@content;
	}
}

@mixin laptop {
	@media only screen and (min-width: 1200px) and (max-width: 1500px) {
		@content;
	}
}

@mixin big-laptop {
	@media only screen and (min-width: 1501px) and (max-width: 1800px) {
		@content;
	}
}

@mixin desktop {
	@media only screen and (min-width: 1801px) {
		@content;
	}
}


/* width */
::-webkit-scrollbar {
	width: 15px;
  }
  
/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
    background-color: #fff;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
	&:hover {
		background-color: darken(#babac0, 15%);
	}
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
    display:none;
}

  
.nav-styles {
	box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.075);
}

.tab-styles {
	background-color: #F8F8F8;
}

.ellipsis-1 {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}

.ellipsis-2 {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.ellipsis-3 {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}

body {
	font-family: 'Rubik', sans-serif;


}

h1 {
	color: $secondary;
	font-weight: 900;
	margin-bottom: 0;
}

p {
	margin-bottom: 0;
}

label {
	font-weight: 500;
	color: $secondary !important;
}


.card {
	margin-bottom: 0;
}

.select__multi-value {
	background-color: $primary !important;
}

// Ajustes del menú de navegación
.main-menu.menu-fixed.menu-light {
	background-color: white;

	.scrollbar-container.main-menu-content {
		margin-top: 20px;
		overflow: visible !important;

		ul.navigation-main {
			background-color: transparent;
			overflow: visible !important;
			display: flex;
			flex-direction: column;
			gap: 5px;
		}
	}
}

// Ajustes al Navbar
.navbar-container {
	padding: 0.5rem !important;
}

.duo-title {
	font-weight: 500;
	font-size: 15px;
	line-height: 18px;
	color: #2C2C26;
	text-transform: capitalize;
}

.custom-style {
	.tooltip-inner {
        max-width: 500px !important;
    }
}

.custom-style-tooltip-fit-content {
	.tooltip-inner {
        max-width: fit-content !important;
    }
}

.section-table {
    .mb-0 {
        margin-bottom: 0 !important;
    }
    .w-inpt {
        max-width: 10px !important;
    }

    td {
        padding: 3px;
    }

    th {
        padding: 1px;
        font-size: 10px;
		min-width: 110px;
    }

    .w-unit-price {
        width: 180px !important;
    }

    .w-offer-price {
        width: 130px !important;
    }
	.w-150 {
        width: 150px !important;
        min-width: 150px !important;
	}

    .w-200 {
        width: 200px !important;
		min-width: 200px !important;
    }
	.w-250 {
        width: 250px !important;
		min-width: 250px !important;
	}
    .w-fit-content {
        min-width: fit-content !important;
    }
}


