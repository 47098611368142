@import "Resources/scss/variables/_variables.scss";

#ToasterComponent{
    .customs-styles-toast {
        width: 100%;
        padding: 15px;
        padding-top: 10px;
        height: 100%;
        background-color: #fff;

		.upper {
			width: 100%;
			height: 32px;
			display: flex;
			flex-direction: row;
			align-items: center;
			background-color: #fff;
			justify-content: space-between;
      		color: rgb(110, 107, 123) !important;

            .btn {
                padding: 0;
            } 

			.title {
				flex-grow: 1;
                font-size: 14px;
				margin-left: 10px;
				font-weight: bold;
                line-height: 21px;
                color: #2C2C26;
			}

			.closeToaster-icon {
				outline: none;
				border-radius: 50%;
			}

            .info-icon {
                color : $toaster-color-info;
            }
        
            .success-icon {
                color : $toaster-color-success;
            }
        
            .warning-icon {
                color: $toaster-color-warning;
            }
        
            .error-icon {
                color: $toaster-color-error;
            }
		}
		
		.lower {
			height: 100%;
			font-style: normal;
			font-weight: normal;
            font-size: 12px;
            line-height: 21px;
            color: #5F5974;
            
		}
	}
}