$primary: #52658f;

$secondary: #03233C;
$secondary-75: mix( $secondary, white, 75 );
$secondary-50: mix( $secondary, white, 50 );
$secondary-25: mix( $secondary, white, 25 );

$danger: #f44336;
$info: #47ACFF;

$grey: #F8F8F8;
$dark-grey: #D8D8D8;

$toaster-color-info : #1B9AAA;
$toaster-color-success : $primary;
$toaster-color-warning : #FF784F;
$toaster-color-error : #DB3A34;

$toaster-border-info : 1.5px solid #1B9AAA;
$toaster-box-shadow-info : 0px 4px 10px rgba(27, 154, 170, 0.25);
$toaster-border-radius-info : 4px;

$toaster-border-success : 1.5px solid #6D711E;
$toaster-box-shadow-success : 0px 4px 10px rgba(109, 113, 30, 0.25);
$toaster-border-radius-success : 4px;

$toaster-border-warning : 1.5px solid #FF784F;
$toaster-box-shadow-warning : 0px 4px 10px rgba(255, 120, 79, 0.25);
$toaster-border-radius-warning : 4px;

$toaster-border-error : 1.5px solid #DB3A34;
$toaster-box-shadow-error : 0px 4px 10px rgba(219, 58, 52, 0.25);
$toaster-border-radius-error : 4px;


